export default {
    GET_FELLOW_PROFILE(state, payload) {
        state.fellow = payload;
    },
    GET_SINGLE_FELLOW(state, payload) {
        state.singlefellow = payload;
    },
    GET_PRESS_RELEASE(state,payload){
        state.pressrelease=payload
    },
    SET_COOKIES(state,payload){
state.emaildetails=payload
    }
};
