import Repository from "../Repository";
const SendMail="send"
export default {

  SendMail(payload){
    return Repository.post(transformRoute(SendMail),payload)
  }
};

const transformRoute = (route) => {
  return `mail/${route}`;
};
