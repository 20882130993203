import RepositoryFactory from "@/apis";
const sendmail = RepositoryFactory.sendmail;
const term = RepositoryFactory.term;
const privacy = RepositoryFactory.privacy;
export default {
  // ####################### SEND_EMAIL ####################
  // ####################### SEND_EMAIL ####################

  SendMail({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sendmail.SendMail(payload);
        resolve(data)
      } catch (error) {
        reject(error);
      }
    });
  },
};
