import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        {
          path: "home",
          name: "Home",
          component: () => import("@/pages/home/Home.vue"),
        },
      ],
    },

    {
      path: "/",
      name: "whitenavbar",
      component: () => import("@/main/MainDark.vue"),
      redirect: "about",
      children: [
        {
          path: "about-us",
          name: "about",
          component: () => import("@/pages/about/About.vue"),
        },
        {
          path: "letter-of-credit",
          name: "listcredit",
          component: () =>
            import("@/pages/tradeservice/listcredit/ListCredit.vue"),
        },
        {
          path: "standby-letter-of-credit",
          name: "standcredit",
          component: () =>
            import("@/pages/tradeservice/standbycredit/StandByCredit.vue"),
        },
        {
          path: "bank-guarantee",
          name: "bank-guarantee",
          component: () =>
            import("@/pages/tradeservice/bankguarantee/BankGuarantee.vue"),
        },
        {
          path: "bank-comfort-letter",
          name: "bank-comfort",
          component: () =>
            import("@/pages/tradeservice/bankcomfort/BankComfort.vue"),
        },
        {
          path: "performance-bank",
          name: "performance-bank",
          component: () =>
            import("@/pages/tradeservice/performancebank/PerformanceBank.vue"),
        },
        {
          path: "advance-payment-guarantee",
          name: "advance-payment-guarantee",
          component: () =>
            import("@/pages/tradeservice/advancepayment/AdvancePayment.vue"),
        },
        {
          path: "list-of-bank",
          name: "list-of-bank",
          component: () => import("@/pages/tradeservice/listbank/ListBank.vue"),
        },
        {
          path: "awards-and-recognition",
          name: "award",
          component: () => import("@/pages/gallery/Award.vue"),
        },
        {
          path: "contact-us",
          name: "contact",
          component: () => import("@/pages/contact/Contact.vue"),
        },

        {
          path: "deals",
          name: "deal",
          component: () => import("@/pages/deal/Deal.vue"),
        },
        {
          path: "private-equity",
          name: "private-equity",
          component: () => import("@/pages/otherservice/PrivateEquity.vue"),
        },
        {
          path: "debt",
          name: "debt",
          component: () => import("@/pages/otherservice/Debt.vue"),
        },
        {
          path: "userful-resource",
          name: "userful-resource",
          component: () => import("@/pages/otherservice/UserfulResource.vue"),
        },
      ],
    },
  ],
});
export default router;
